export enum EPermissions {
  OrderManage = "order:manage",
  OrderDelete = "order:delete",
  ManufacturingManage = "manufacturing:manage",
  ShippingManage = "shipping:manage",
  ProofManage = "proof:manage",
}

export const AppName = "Glide";
export const BaseDocsUrl = process.env.NEXT_PUBLIC_DOCS_BASE_URL
  ? process.env.NEXT_PUBLIC_DOCS_BASE_URL
  : process.env.NEXT_PUBLIC_RELEASE_STAGE === "dev"
  ? "http://localhost:3001"
  : process.env.NEXT_PUBLIC_RELEASE_STAGE === "staging"
  ? "https://docs.glidenow.rocks"
  : "https://docs.glidenow.com";

export const devOverrideApiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT;

export const ShopifyIntegrationID =
  "gid://glide/Integration/ig-clpwphu01000108l95igm1d6u";

export const S3IntegrationID =
  "gid://glide/Integration/ig-clpwpglh7000008l9e9uc0h58";

export const OrderDeskIntegrationID =
  "gid://glide/Integration/ig-cm82xh1jm000008lg8jxp1fl6";

export const DATE_FORMAT = "yyyy-MM-dd @ HH:mm:ss";

export const FILE_PURPOSE_PROOF =
  "gid://glide/FilePurpose/fp-clv95gh1l0006olmewfy1nmof";
